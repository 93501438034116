import React, { useEffect, useState } from "react";
import { useCart } from "../../Context/CartContext";
import { useNavigate } from "react-router-dom";
import { useProductList } from "../../Context/ProductListContext";
import "./Cart.css";

import nophoto from "../../Images/nophotosmall.png";

export const Cart = ({ formatCurrency }) => {
  const { cartItems, addOrRemoveFromCart, removeFromCart, fetchCartItems } =
    useCart();

  const navigate = useNavigate();
  const { productImages, prices } = useProductList();
  const [imageUrls, setImageUrls] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    fetchCartItems();
  }, [fetchCartItems]);

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      let totalPrice = 0;
      cartItems.forEach((item) => {
        const images = productImages[item.ProductID];
        if (images && images.length > 0) {
          setImageUrls((prevState) => ({
            ...prevState,
            [item.ID]: images[0],
          }));
        }
        const price = prices.find(
          (price) => price.productId === item.ProductID
        );
        totalPrice += (price?.price || 0) * item.Qty;
      });
      setTotalPrice(totalPrice);
    }
  }, [cartItems, productImages, prices]);

  return (
    <div className="container">
      {cartItems && cartItems.length > 0 ? (
        <div>
          <div className="h4 fw-bolder text-center mt-5">SHOPPING CART</div>
          <div className="mt-5 mb-5">
            {cartItems.map((item, index) => {
              const price = prices.find(
                (price) => price.productId === item.ProductID
              );

              return (
                <div
                  key={index}
                  className="row justify-content-center align-items-center mt-5"
                >
                  <div className="col ms-3">
                    {imageUrls && imageUrls[item.ID] ? (
                      <img
                        className=""
                        src={imageUrls[item.ID].imageUrl}
                        width={150}
                        alt="game"
                      />
                    ) : (
                      <img className="" src={nophoto} width={150} alt="game" />
                    )}
                  </div>
                  <div className="col">
                    <div className="fw-bolder">
                      {item.Product && item.Product.Name}
                    </div>{" "}
                    <div className="fw-bolder">
                      {item.Product && item.Product.Code}
                    </div>
                    {prices && (
                      <div className="fw-bolder">
                        DKK:{" "}
                        {price?.price
                          ? formatCurrency(price.price)
                          : "Loading..."}{" "}
                      </div>
                    )}
                    <div id="showQty" className="col text-center">
                      <div className="row">
                        <div className="input-group" style={{ width: "9rem" }}>
                          <span
                            className="input-group-text rounded-0 btn shadow-none border  btn-light"
                            id="decrement"
                            onClick={() => {
                              addOrRemoveFromCart(item, -1);
                            }}
                          >
                            -
                          </span>
                          <input
                            name="inputQtySmall"
                            type="text"
                            className="form-control  text-center"
                            placeholder={item.Qty}
                            aria-label="Username"
                            aria-describedby="decrement"
                            readOnly
                            value={item.Qty}
                          />
                          <span
                            className="input-group-text rounded-0 btn shadow-none border btn-light"
                            id="increment"
                            onClick={() => {
                              addOrRemoveFromCart(item, +1);
                            }}
                          >
                            +
                          </span>
                        </div>
                      </div>
                      <div
                        className="removeItem row fw-bolder text-body-secondary ms-1 pt-2 border-bottom"
                        onClick={() => {
                          removeFromCart(item);
                        }}
                      >
                        remove
                      </div>
                    </div>
                  </div>
                  <div id="hideQty" className="col text-center">
                    <div className="row">
                      <div className="input-group" style={{ width: "9rem" }}>
                        <span
                          className="input-group-text rounded-0 btn  shadow-none btn-light"
                          id="decrement"
                          onClick={() => {
                            addOrRemoveFromCart(item, -1);
                          }}
                        >
                          -
                        </span>
                        <input
                          name="inputQty"
                          type="text"
                          className="form-control text-center border-0"
                          aria-label="Username"
                          aria-describedby="decrement"
                          readOnly
                          value={item.Qty}
                        />
                        <span
                          className="input-group-text rounded-0 btn shadow-none btn-light"
                          id="increment"
                          onClick={() => {
                            addOrRemoveFromCart(item, 1);
                          }}
                        >
                          +
                        </span>
                      </div>
                    </div>
                    <div
                      className="removeItem row fw-bolder text-body-secondary ms-4 pt-2 border-bottom"
                      onClick={() => {
                        removeFromCart(item);
                      }}
                    >
                      remove
                    </div>
                  </div>

                  <div id="hideTotalPrice" className="col fw-bolder  text-end ">
                    DKK:{" "}
                    {price
                      ? formatCurrency(price.price * item.Qty)
                      : "Loading..."}
                  </div>
                </div>
              );
            })}
          </div>
          <hr />
          <div className="mt-3 text-end">
        
          <div className="fw-bolder mb-2">
            TOTAL: {totalPrice ? formatCurrency(totalPrice) : "Loading..."}
          </div>
          {/* New "Shop more..." button */}
          <button
            className="btn rounded-0 btn-secondary me-2" // Added margin-end for spacing
            onClick={() => navigate("/shop")}
          >
            Shop more
          </button>
          {/* Existing "Checkout" button */}
          <button
            className="btn rounded-0 btn-secondary"
            onClick={() => navigate("/shop/checkout")}
          >
            Checkout
          </button>
      
      </div>

        </div>
      ) : (
        <div className="justify-content-center align-items-center text-center">
          <div className=" h3 mt-5 fw-bolder">THE CART IS EMPTY</div>
          <div className=" h5 mt-5 fw-bolder ">
            <button
              className="btn btn-secondary"
              onClick={() => navigate("/shop")}
            >
              <div className="m-1">GO TO PRODUCTS</div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
