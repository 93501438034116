  import "./Product.css";
  import { useProductList } from "../../Context/ProductListContext";
  import InfiniteScroll from "react-infinite-scroll-component";
  import { useCart } from "../../Context/CartContext";
  import nophoto from "../../Images/nophotosmall.png";
  import { useNavigate } from "react-router-dom";
  import React, { useEffect, useCallback, useState,useRef} from "react";
  import { Oval } from "react-loader-spinner";
  import { CircleFlag } from "react-circle-flags";
  
  
  export const Products = React.memo(
    ({
      handleQuantityChange,
      productQuantities,
      setProductQuantities,
      formatCurrency,
    }) => {
             
      const {
        productImages,
        setShowItems,
        productList,
        prices,
        showItems,
      } = useProductList();
      const { addToCart, fetchCartItems, } = useCart();
      const navigate = useNavigate();
    
      const [loadedImages, setLoadedImages] = useState({});
      const [loading, setLoading] = useState(false); // Loading state
      const loadMoreRef = useRef(null);

   
      
    // Clear session storage on window unload
      useEffect(() => {
        const handleBeforeUnload = () => {
          window.sessionStorage.clear();
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
        };
      }, []);

      // Initialize product quantities
      useEffect(() => {
        const initialQuantities = {};
        productList.forEach((product) => {
          initialQuantities[product.ID] = product.CaseQty;
        });
        setProductQuantities(initialQuantities);
      }, [productList, setProductQuantities]);

    // Fetch more products on scroll or button click
    const fetchMoreData = useCallback(() => {
      setLoading(true); // Start showing spinner
      setTimeout(() => {
        setShowItems((prevValue) => prevValue + 16); // Show next 16 products
        sessionStorage.setItem("showItems", showItems + 16); // Store in session
      
        // Scroll into view after loading finishes
        if (loadMoreRef.current) {
          loadMoreRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        setLoading(false); // Stop showing spinner
    
      }, 500); // Simulate delay
    }, [setShowItems,showItems]);
    


      const addItemsToCart = useCallback(
        (productId, qty) => {
          addToCart(productId, qty);
        
          setProductQuantities((prevQuantities) => ({
            ...prevQuantities,
            [productId]: productList.find((product) => product.ID === productId)
              .CaseQty,
          }));
          fetchCartItems();
        },
        [addToCart, productList, setProductQuantities, fetchCartItems]
      );

      useEffect(() => {
        fetchCartItems();
        // eslint-disable-next-line
      },[])

      const handleProductClick = useCallback(
        (productId) => navigate(`/shop/product/${productId}`),
        [navigate]
      );


      const handleImageLoad = (productId) => {
        setLoadedImages((prevLoadedImages) => ({
          ...prevLoadedImages,
          [productId]: true,
        }));
      };




      return (
        <>
        {/* Check if productList and productImages exist */}
        {productList && productImages ? (
          <>
            <InfiniteScroll style={{
              overflowX: 'hidden', // This hides the horizontal scrollbar
              overflowY: 'auto',   // Keep vertical scrolling enabled
             
              }} 
              className="infinite-scroll-container"
              dataLength={productList.length} // Number of items loaded so far
              next={fetchMoreData} 
              hasMore={true}
              loader={ // Conditionally show spinner only when loading
                loading && (
                  <div className="spinner-overlay text-center">
                    <Oval
                      secondaryColor="grey"
                      visible={true}
                      height={200}
                      width={200}
                      color="blue"
                    
                    />
                  {/*  <span className="ms-2">Loading...</span>*/}
                  </div>
                  
                )
              }
              endMessage={<p>End of list</p>} // Message displayed when all items are loaded
              scrollThreshold={0.9} // Trigger more data at 90% of scroll
              scrollableTarget="window" // Using window scroll instead of a specific div
            >
              {/* Map through productList and render each product */}
             
              <div className={`row ${productList.length === 1 ? 'row-cols-2' : 'row-cols-1 row-cols-md-2 row-cols-sm-2 row-cols-lg-4'}`}>

                {productList.map((product) => {
                  const productImagesForCurrentProduct = productImages[product.ID];
                  const firstImage =
                    productImagesForCurrentProduct &&
                    productImagesForCurrentProduct.length > 0
                      ? productImagesForCurrentProduct[0].imageUrl
                      : nophoto;
      
                  const propertiesWithID3 = product.Properties.filter(
                    (property) => property.PropertyID === 3
                  );
                  const productPrice = prices.find(
                    (price) => price.productId === product.ID
                  );
      
                  return (
                    <div key={product.ID} className="col mb-4">
                      <div className="card rounded-0 border-0">
                        <div className="d-flex justify-content-center">
                          <img
                            className={`productImage m-2 mt-3 ${
                              loadedImages[product.ID] ? "loaded" : "unloaded"
                            }`}
                            src={firstImage}
                            alt="product"
                            onLoad={() => handleImageLoad(product.ID)}
                            onClick={() => handleProductClick(product.ID)}
                          />
                        </div>
                        <div className="card-body">
                          <h5
                            className="card-title"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {product.Name}
                          </h5>
                        </div>
                        <ul className="list-group list-group-flush fw-medium">
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {product.Code}
                            </span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <div>
                              {/* Country flags */}
                              {propertiesWithID3.map((property) => {
                                const flagMap = {
                                  Norwegian: "no",
                                  Danish: "dk",
                                  Swedish: "se",
                                  English: "gb",
                                  Finnish: "fi",
                                };
                                const countryCode =
                                  flagMap[property.PropertyValue.Name];
                                if (countryCode) {
                                  return (
                                    <CircleFlag
                                      countryCode={countryCode}
                                      height="20"
                                      className="ms-1"
                                      key={`${property.PropertyValue.ID}-${countryCode}`}
                                    />
                                  );
                                }
                                return (
                                  <span key={property.PropertyValue.ID}>
                                    {property.PropertyValue.Name}
                                  </span>
                                );
                              })}
                            </div>
                          </li>
      
                          {/* Displaying Price */}
                          <li className="list-group-item">
                            <div className="d-flex align-items-center">
                              DKK: {""}
                              {productPrice ? (
                                formatCurrency(productPrice?.price)
                              ) : (
                                <span className="">
                                  <Oval
                                    secondaryColor="black"
                                    visible={true}
                                    height="20"
                                    width="35"
                                    color="black"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                  />
                                </span>
                              )}
                            </div>
                          </li>
      
                          {/* Quantity Input and Add to Cart */}
                          <li className="list-group-item input-group">
                            <div className="input-group">
                              <input
                                id={product.ID}
                                min={1}
                                type="number"
                                className="form-control rounded-0 border-end-0"
                                placeholder={product.CaseQty.toString()}
                                aria-label="Quantity"
                                aria-describedby="basic-addon2"
                                value={productQuantities[product.ID] || ""}
                                onChange={(e) =>
                                  handleQuantityChange(product.ID, e.target.value)
                                }
                              />
                              <span
                                className="input-group-text rounded-0 border-start-0"
                                onClick={() => {
                                  addItemsToCart(
                                    product.ID,
                                    productQuantities[product.ID]
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <i
                                  className="bi bi-cart4"
                                  style={{ fontSize: "1.3rem" }}
                                ></i>
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div> 
             
            </InfiniteScroll>
          
           
          </>
        ) : (
          <div>No products available</div>
        )}
      </>
      
    );    }) 