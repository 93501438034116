import { fetchData } from "../Utils/ReusableFunctions";

export const createProduct = async (
  apiUrl,
  sessionToken,
  product,
  notify,
  setCustomMessage,
  setProduct,
  setSelectedValue
) => {
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(product),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("User created:", data);
      setCustomMessage("Product created");
      setProduct({
        ProductDiscountGroupID: "",
        Active: true,
        Name: "",
        Code: "",
        Inventory: "",
        Description: "",
        CaseQty: "",
      });
      setSelectedValue(null);
    } else {
      notify("Error creating product");
      throw Error("Failed to create product");
    }
  } catch (error) {
    notify("Error creating product");
    console.error("Error creating product:", error);
  }
};

export const updateProduct = async (
  apiUrl,
  sessionToken,
  selectedProduct,
  fetchData,
  setProductList,
  notify,
  setCustomMessage
) => {
  try {
    if (selectedProduct) {
      const patchUrl = `${apiUrl}(${selectedProduct.ID})`;
      const patchData = {
        Code: selectedProduct.Code,
        Name: selectedProduct.Name,
        Description: selectedProduct.Description,
        Active: selectedProduct.Active,
        ProductDiscountGroupID: selectedProduct.ProductDiscountGroupID,
      };

      const response = await fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      });

      if (response.ok) {
        console.log("Product updated successfully");
        setCustomMessage("Product updated successfully");
        fetchData(apiUrl, sessionToken).then((data) => {
          setProductList(data.value);
        });
      } else {
        notify("Error updating product");
        console.error("Error updating product. Status:", response.status);
      }
    }
  } catch (error) {
    notify("Error updating product");
    console.error("Error updating product:", error);
  }
};

export const fetchProductImages = async (
  productList,
  sessionToken,
  ApiUrls,
  setProductImages
) => {
  const imageMap = {};
  await Promise.all(
    productList.flatMap(async (product) => {
      if (product.Images) {
        const imagePromises = product.Images.map(async (image) => {
          try {
            const imageUrlResponse = await fetch(
              `${ApiUrls.getProductImageApi}(id=${image.ID},height=300)`,
              {
                headers: {
                  Authorization: `Bearer ${sessionToken}`,
                },
              }
            );
            if (!imageUrlResponse.ok) {
              throw new Error(
                `Failed to fetch image for product ID ${product.ID}: ${imageUrlResponse.statusText}`
              );
            }
            const blob = await imageUrlResponse.blob();
            const imageUrl = URL.createObjectURL(blob);
            return {
              productId: product.ID,
              imageUrl: imageUrl,
              imageID: image.ID,
              SortOrder: image.SortOrder,
            };
          } catch (error) {
            console.error(
              `Error fetching image for product ID ${product.ID} and image ID ${image.ID}:`,
              error
            );
            return {
              productId: product.ID,
              imageUrl: null,
              imageID: image.ID,
              SortOrder: image.SortOrder,
            };
          }
        });
        const images = await Promise.all(imagePromises);
        images.forEach(({ productId, imageUrl, imageID, SortOrder }) => {
          if (!imageMap[productId]) {
            imageMap[productId] = [];
          }
          imageMap[productId].push({ imageUrl, imageID, SortOrder });
        });
      }
    })
  );
  setProductImages(imageMap);

};

export const fetchDataAndUpdateState = async (
  apiUrl,
  sessionToken,
  setStateCallback,
) => {
  try {
    const data = await fetchData(apiUrl, sessionToken);
    setStateCallback(data.value);
  } catch (error) {
    console.error(`Error fetching data from ${apiUrl}:`, error);
  }
};

export const readFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};


