
import React from "react";
import "./FilterAndSortContainer.css";
import  {OffCanvas} from "../Navigation/OffCanvas";
import { useProductList } from "../../Context/ProductListContext";
import { useState,useEffect } from "react";


export const FilterAndSortContainer = () => {
  const { setSearchVariable } = useProductList();
  const [searchTerm, setSearchTerm] = useState('');


  useEffect(() => {
    const debounce = setTimeout(() => {
      setSearchVariable(searchTerm);  // Set the search value only after a delay
    }, 500); // Debounce time (500ms)

    return () => clearTimeout(debounce); // Cleanup debounce on re-renders
  }, [searchTerm, setSearchVariable]);

  return (
    <div className="w-100 border-bottom filter-sort-container" >       
      <div className="row">
        <div className="col w-50 d-flex justify-content-center align-items-center">
          <input
          name="searchInputSmall"
            type="text"
            className="form-control rounded-0 border-0 w-75"
            placeholder="SEARCH"
            aria-label="Search"
            aria-describedby="basic-addon2"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div
          className="col w-50 border-start"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <div className=" fw-bolder">
            <div className="filter-products  d-flex justify-content-center align-items-center">
              <div className="filtersort">FILTER - SORT</div>
            </div>
          </div>
        </div>
      </div>
      <OffCanvas />
    </div>
  );
};




